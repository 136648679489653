<template>
  <RevButtonBase
    class="text-static-default-hi text-center"
    :rel="link?.rel"
    :target="link?.target"
    :to="link?.href"
    @click="() => trackClick(tracking)"
  >
    <RevCard class="h-full p-32" :hoverable="true">
      <div class="flex h-full flex-col items-center justify-center gap-24">
        <div class="body-1">"{{ text }}"</div>
        <ImageRenderer
          :alt="image?.alt"
          class="h-72"
          :height="image?.height"
          :height-mobile="image?.heightMobile"
          :loading
          :src="image?.src"
          :src-mobile="image?.srcMobile"
          :width="image?.width"
          :width-mobile="image?.widthMobile"
        />

        <div class="body-2">
          {{ publicationDate }}
        </div>
      </div>
    </RevCard>
  </RevButtonBase>
</template>

<script lang="ts" setup>
import type { PressArticleContent } from '@backmarket/http-api/src/api-specs-content/models/press-article-content'
import type { Tracking } from '@backmarket/http-api/src/api-specs-content/models/tracking'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCard } from '@ds/components/Card'

import { Loading } from '../../shared-components/ImageRenderer/ImageRenderer.constants'
import ImageRenderer from '../../shared-components/ImageRenderer/ImageRenderer.vue'

export type PressArticleCardProps = PressArticleContent & {
  loading?: Loading
  tracking: Tracking
}

withDefaults(defineProps<PressArticleCardProps>(), {
  loading: Loading.Lazy,
})

const { trackClick } = useTracking()
</script>
